@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css?family=Archivo+Black|Poppins|Roboto&display=swap);


    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
    }

    body {
        margin: 0px;
        font-family: 'Roboto', sans-serif;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        touch-action: manipulation;

    }
    .heading-helper {      
      width: 100%;
      background-color: rgb(240, 240, 255);
      text-align: center;
    }

    .heading {      
        color: black;
        width: calc(100% - 100px);
        padding: 50px;
        padding-bottom: 20px;
        padding-top: 80px;
    }

    .info-bar {
      background-color: rgb(83, 83, 83);
      color: white;
      width: 100%;
      text-align: center;
      font-size: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .logo {
        font-size: 56px;
        color: black;
        font-family: 'Archivo Black', sans-serif;
    }

    .tagline {
      color: rgb(83, 83, 83);
      font-family: 'Archivo Black', sans-serif;
    }

    .stream-holder {
        padding-top: 10px;
        width: 600px;
        margin-left: calc(50% - 280px);
    }

    .stream {
        padding-top: 20px;
        text-align: center;
        margin: 8px;
        height: 110px;
        width: 90px;
        border-radius: 15px;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        color: white;
        background-color: black;
        display: inline-block;
        outline: none;
    }

    .stream:hover {
        transform: scale(1.10);
        transition: all 0.05s ease-in;
    }

    .bottom {
        width: 600px;
        margin-left: calc(50% - 280px);
        font-size: 12px;
        color: grey;
    }

    .submit {
        color: grey;
        border: 1px solid grey;
        float: right;
    }

    .stream-viewer {
        position: fixed;
        left: 0px;
        top: 0px;
        max-width: 100%;
        min-width: 100%;
        max-height: 100%;
        min-height: 100%;
        color: white;
        background-color: black;
        background-size: cover;
    }

    .stream-box {
      position: absolute;
      left: 0;
        font-family: 'Poppins', sans-serif;
        width: 600px;
        padding: 20px;
        margin-top: 20%;
        margin-left: calc(50% - 300px);
        font-size: 36px;
        line-height: 1.5em;
        background-color: rgba(0, 0, 0, 0.705);
        border-radius: 15px;
        z-index: 2;
        -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
        overflow:hidden;
    }

    #spinner {
        text-align: center;
        font-size: 36px;
        font-weight: bold;

        -webkit-animation-name: pulse;

                animation-name: pulse;
        -webkit-animation-duration: 1000ms;
                animation-duration: 1000ms;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
    }


    @-webkit-keyframes pulse {
        from {
            transform: scale3d(1, 1, 1);
        }

        50% {
            transform: scale3d(1.2, 1.2, 1.2);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
        }


    @keyframes pulse {
        from {
            transform: scale3d(1, 1, 1);
        }

        50% {
            transform: scale3d(1.2, 1.2, 1.2);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
        }

.progress-thing {
  background-color: rgba(0, 0, 0, 0.705);
  color: white;
  width: 120px;
  height: 45px;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
}

.progress-bar {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.884);
}

.stream-viewer-close {
  transform: rotate(180deg);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  flex: 0 1;
}

.muter-holder {
  flex: 1 1;
  text-align: right;
  padding-top: 10px;
  padding-right: 5px;
}


.stream-viewer-close-button {
  color: white;
  font-size: 26px;
  line-height: 1em;
  background-color: rgba(0, 0, 0, 0.705);
  padding: 10px;
  border-radius: 15px;

  display: inline-block;
  text-align: center;
  z-index: 2;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.overlay-button {
  background-color: rgba(0, 0, 0, 0.705);
  padding: 10px;
  border-radius: 15px;
  display: inline-block;
  font-size: 16px;
  color: white;
  opacity: 1;
}

.overlay-button:hover {
  opacity: 1 !important;
}

.stream-viewer-close-button:hover {
  background-color: white;
  color: black;
}

.stream-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 10000;
}

.stream-viewer-overlay-leftright {
  display: flex;
  height: 90%;
}

.stream-viewer-overlay-left {
  flex: 1 1;
  cursor: pointer;
  outline: none !important;
  outline-offset: none !important;
  /*background-color: rgba(255, 0, 0, 0.363);*/
}

.stream-viewer-overlay-right {
  flex: 1 1;
  cursor: pointer;
  outline: none !important;
  outline-offset: none !important;
  /*background-color: rgba(0, 128, 0, 0.39);*/
}

.stream-viewer-overlay-top {
  flex: 1 1;
  width: 100%;
  min-height: 70px;
  display: flex;

  /*background-color: rgba(128, 0, 128, 0.473);*/
}

.stream-viewer-overlay-bottom {
  padding: 20px;
  width: calc(100% - 40px);
  text-align: center;
  display: flex;
  /*background-color: rgba(128, 0, 128, 0.473);*/
}

.stream-viewer-overlay-bottom-button {
  font-size: 18px;

  color: white;
  background-color: rgba(0, 0, 0, 0.705);
  display: inline-block;
  padding: 10px;
  border-radius: 15px;

  cursor: pointer;
  text-align: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  text-decoration: underline;
}

.stream-viewer-overlay-bottom-button:hover {
  background-color: white;
  color: black;
}

.stream-viewer-overlay-left-button {
  font-size: 18px;
  color: white;
  position: absolute;
  cursor: pointer;
  left: 25px;
  top: 35%;
}

.stream-viewer-overlay-right-button {
  font-size: 18px;
  color: white;
  position: absolute;
  cursor: pointer;
  right: 25px;
  top: 35%;
}

.overall-progress-bar-holder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.705);
  width: 100%;
  text-align: left;
  padding: 0px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.overall-progress-bar {
  background-color: white;
  height: 5px;
}

.stream-holder-holder {
  text-align: left;
}

.video-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 0;
}
@media (min-aspect-ratio: 16/9) {
  .video-background iframe {
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-background iframe {
    width: 177.78vh;
  }
}

.shifted-down {
  margin-top: 35%;
  font-size: 20px;
}

.unmuter {
  z-index: 2000;
  color: white;
  background-color: rgba(0, 0, 0, 0.705);
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  text-decoration: underline;
  border-radius: 15px;
}

.source-info {
  color: white;
  font-size: 12px;
  border-radius: 15px;
  opacity: 0.4;
}

.stream-name-holder {
  color: white;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.705);
  display: inline-block;
  border-radius: 15px;
  margin-top: 10px;
  margin-left: 5px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.center-viewer {
  width: 100%;
  text-align: center;
  margin-top: 20%;
}

.logo-center-viewer {
}

.logo-center-img {
  width: 100px;
  height: auto;
}

.end-screen {
  transform: scale(1.1);
  -webkit-animation: pulse 1.5s infinite;
          animation: pulse 1.5s infinite;
  padding: 15px;
}

.tip-holder {
  color: black;
  font-size: 18px;
  margin: 25px;
  border-radius: 15px;;
}

@keyframes pulse {
	0% {
		transform: scale(1.0);
	}

	70% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1.0);
	}
}

.paused-box {
  margin-top: 5px !important;
  font-size: 18px !important;
  padding: 10px;
  border-radius: 0px;
}

.content-holder {
  background-color: white;
  color: black;
  width: calc(100% - 40px);
  font-size: 20px;
  padding: 20px;
  line-height: 1.8em;
  text-align: left;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  max-height: 10%;
}

.content-holder img {
  display: none;
}


@media (max-width:700px) {
  body {
    text-align: center;
  }
  .stream-holder {
    width: inherit;
    margin: 0px;
  }
  .heading {
    padding-top: 20px;
  }
  .stream-box {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    margin-top: 250px;
    font-size: 36px;
    line-height: 1.5em;
  }

  .shifted-down {
    margin-top: 35%;
    font-size: 16px;
  }

  .stream-viewer-overlay-left-button {
    /*display: none;*/
    top: 75%;
    opacity: 0.4;
  }

  .stream-viewer-overlay-right-button {
    /*display: none;*/
    top: 75%;
    opacity: 0.4;
  }

  .stream-box {
    border-radius: 0px;
  }
}


@media (max-width:700px) and (orientation: portrait) {
  .stream-holder {
    width: inherit;
    margin: 0px;
  }
  body {
    text-align: center;
  }
  .heading {
    padding-top: 20px;
  }
  .stream-box {
    font-family: 'Poppins', sans-serif;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin: 0px;
    margin-top: 30%;
    font-size: 24px;
    line-height: 1.5em;
  }

.logo {
  font-size: 32px;
}

.tagline {
  font-size: 14px;
}

  .stream-holder-holder {
    text-align: left;
    max-width: 320px;
    margin-left: calc(50% - 160px);
  }

  .stream:hover {
    transform: none;
  }
}
